<template>
  <div class="report-list">
    <h2 class="title title--indent">Проведенные скидки</h2>
    <div v-if="logs.length" class="report-list__content">
      <div
        v-for="log in logs"
        :key="log.id"
        class="report-list__item"
      >
        <div class="report-list__point">
          <div class="report-list__name">ID:</div>
          <div class="report-list__value">{{ log.id }}</div>
        </div>
        <div class="report-list__point">
          <div class="report-list__name">Номер карты:</div>
          <div class="report-list__value">{{ log.number }}</div>
        </div>
        <div class="report-list__point">
          <div class="report-list__name">Дата создания:</div>
          <div class="report-list__value">{{ log.created_at }}</div>
        </div>
        <div v-if="log.comment" class="report-list__point report-list__point--comment">
          <div class="report-list__name">Комментарий:</div>
          <div class="report-list__value">{{ log.comment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscountLog',
  data() {
    return {
      logs: [],
    };
  },
  mounted() {
    this.fetchLogs();
  },
  methods: {
    fetchLogs() {
      this.$store.dispatch('partner/report/GET_DISCOUNT_LOG')
        .then(response => {
          this.logs = response.data.results;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
  },
};
</script>